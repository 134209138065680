/* eslint react/jsx-props-no-spreading: 0 */

import { Box, Button, Divider, Flex, Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useListProducts } from '@/client/services/hooks/content/products/useListProducts';
import { OrderingDirection, Product } from '@/client/services/api/graphql/gql/graphql';
import { PaginationState } from '@tanstack/react-table';
import ProductCard from '@/client/components/data-display/cards/ProductCard';
import { useAuthStore } from '@/client/services/state/authStore';
import { useQuery, useMutation } from '@tanstack/react-query';
import ProductsService from '@/client/services/api/graphql/ProductsService';
import EcommerceService from '@/client/services/api/graphql/EcommerceService';
import { useToastStore } from '@/client/services/state/toastStore';
import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';

type Pages = 'change' | 'finalize';

export default function ChangeSubscription() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { id: originalSubscriptionId } = useParams();
  const [selectedSubscription, setSelectedSubscription] = useState<Product | null>(null);
  const [page, setPage] = useState<Pages>('change');

  const durationToDisplay: { [key: string]: string } = {
    MONTHLY: 'monthly',
    YEARLY: 'annually',
  };

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // TODO: See if Stripe has this for us, this might be an estimate
  const calculateProration = (purchaseDate: string, price: number, duration: string) => {
    // Calculate the end of the cycle from purchaseDate
    const start: any = new Date(purchaseDate);
    const cycleEnd: any = new Date(purchaseDate);
    // https://stackoverflow.com/questions/33070428/add-a-year-to-todays-date
    if (duration === 'MONTHLY') {
      cycleEnd.setMonth(cycleEnd.getMonth() + 1);
    }
    if (duration === 'YEARLY') {
      cycleEnd.setFullYear(cycleEnd.getFullYear() + 1);
    }

    // subtract today from that date
    // https://stackoverflow.com/questions/4944750/how-to-subtract-date-time-in-javascript
    const now: any = new Date();
    const difference = Math.abs(cycleEnd - now);

    // divide that number by the length of the cycle?
    const cycleLength = Math.abs(cycleEnd - start);
    const timeLeft = difference / cycleLength;

    // multiply price by that number
    const prorate = price * timeLeft;
    return prorate.toFixed(2);
  };

  const { data: originalSubscriptionData } = useQuery({
    queryKey: ['currently-active-purchase', user._id, originalSubscriptionId],
    queryFn: () =>
      ProductsService.listPurchases({
        user: { in: [user._id] },
        id: { in: [originalSubscriptionId!] },
        subscriptionActive: true,
        page: 0,
        pageSize: 10,
        includeTotalCount: true,
      }),
  });

  const createSubscription = useMutation({
    mutationFn: async () => EcommerceService.createSubscription(selectedSubscription?.id!),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('ecommerce.subscriptionManagement.changeSubscriptionSuccess'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('ecommerce.subscriptionManagement.changeSubscriptionError'),
      }),
  });

  // TODO: List current subscription first always?
  const { data } = useListProducts({
    page: pageIndex,
    pageSize,
    includeTotalCount: true,
    ordering: [{ field: 'purchaseCount', direction: OrderingDirection.Desc }],
  });

  return (
    <Box margin={12}>
      {page === 'change' && (
        <>
          <OutlineButton
            onClick={() => navigate(`/manage-subscription/${originalSubscriptionId}`)}
          >
            {t('ecommerce.subscriptionManagement.headline')}
          </OutlineButton>
          <Stack spacing={8} paddingX={20} paddingY={12}>
            <Text fontSize="xl" fontWeight="600">
              {t('ecommerce.subscriptionManagement.changeSubscription')}
            </Text>

            {data?.products?.map((product: Product) => {
              const isSelected =
                originalSubscriptionData?.purchases[0].productDetails.id === product.id;
              return (
                <ProductCard
                  product={{
                    title: product.title,
                    description: product.description,
                    price: product.price,
                    subscription_duration: product.subscriptionDuration,
                    _id: product.id,
                    images: product.images,
                  }}
                  managementButton={
                    <SolidButton
                      onClick={() => {
                        setSelectedSubscription(product);
                        setPage('finalize');
                      }}
                      width={200}
                      isDisabled={isSelected}
                    >
                      {isSelected
                        ? t('ecommerce.subscriptionManagement.currentSubscription')
                        : t('ecommerce.subscriptionManagement.changeSubscription')}
                    </SolidButton>
                  }
                />
              );
            })}
            {/* TODO: Add pagination after discussing with product */}
            <Flex justifyContent="space-between">
              <Button
                variant="adminBrandPrimary"
                borderRadius={6}
                isDisabled={!pageIndex}
                onClick={() =>
                  setPagination({
                    pageIndex: pageIndex - 1,
                    pageSize,
                  })
                }
              >
                {t('authoring.previous')}
              </Button>
              <Button
                variant="adminBrandPrimary"
                borderRadius={6}
                // isDisabled={isDisabled}
                onClick={() =>
                  setPagination({
                    pageIndex: pageIndex + 1,
                    pageSize,
                  })
                }
              >
                {t('authoring.next')}
              </Button>
            </Flex>
          </Stack>
        </>
      )}
      {page === 'finalize' && (
        <>
        <OutlineButton
          onClick={() => setPage('change')}
        >
          {t('ecommerce.subscriptionManagement.changeSubscription')}
        </OutlineButton>
          <Flex
            direction="column"
            h="100%"
            borderColor="lightgray"
            border="1px solid"
            borderRadius={10}
            marginY={10}
            marginX="20%"
            paddingY={10}
            paddingX={16}
          >
            <Text fontWeight="600" textAlign="left" marginBottom={4}>
              {t('ecommerce.checkout.summary')}
            </Text>
            <Grid
              templateColumns="30% 70%"
              templateRows="10% 5% 10% 1% 10% 5% 10% 1% 10% 10% 20% 5%"
            >
              <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1}>
                <Text fontSize="sm" fontWeight="500" textAlign="left" marginY={5}>
                  {t('ecommerce.subscriptionManagement.currentSubscription')}:
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={1} rowSpan={1}>
                <Text fontSize="sm" textAlign="left" marginTop={5} fontWeight={600}>
                  {originalSubscriptionData?.purchases[0].productDetails.title}
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={2} rowSpan={1}>
                <Text fontSize="sm" textAlign="left">
                  {`$${originalSubscriptionData?.purchases[0].productDetails.price} / ${
                    durationToDisplay[
                      originalSubscriptionData?.purchases[0].productDetails.subscriptionDuration!
                    ]
                  }`}
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={3} rowSpan={1}>
                <Text fontSize="sm" textAlign="left">
                  {`${t('ecommerce.subscriptionManagement.actionCancel')} `}
                </Text>
              </GridItem>
              <GridItem colStart={1} colSpan={2} rowStart={4} rowSpan={1}>
                <Divider borderTop=".25px solid" opacity="20%" />
              </GridItem>
              <GridItem colStart={1} colSpan={1} rowStart={5} rowSpan={1}>
                <Text fontSize="sm" fontWeight="500" textAlign="left" marginY={5}>
                  {t('ecommerce.subscriptionManagement.updatedSubscription')}:
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={5} rowSpan={1}>
                <Text fontSize="sm" textAlign="left" marginTop={5} fontWeight={600}>
                  {selectedSubscription?.title}
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={6} rowSpan={1}>
                <Text fontSize="sm" textAlign="left">
                  {`$${selectedSubscription?.price} / ${
                    durationToDisplay[selectedSubscription?.subscriptionDuration!]
                  }`}
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={7} rowSpan={1}>
                <Text fontSize="sm" textAlign="left">
                  {`${t('ecommerce.subscriptionManagement.actionActivate')} `}
                </Text>
              </GridItem>
              <GridItem colStart={1} colSpan={2} rowStart={8} rowSpan={1}>
                <Divider borderTop=".25px solid" opacity="20%" />
              </GridItem>
              <GridItem colStart={1} colSpan={1} rowStart={9} rowSpan={1}>
                <Text fontSize="sm" fontWeight="500" textAlign="left" marginY={5}>
                  {t('ecommerce.checkout.howItWorks')}:
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={9} rowSpan={1}>
                <Text fontSize="sm" textAlign="left" marginY={5}>
                  {t('ecommerce.subscriptionManagement.changeSubscriptionHowItWorks1')}
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={10} rowSpan={1}>
                <Text fontSize="sm" textAlign="left" marginY={5}>
                  {t('ecommerce.subscriptionManagement.changeSubscriptionHowItWorks2', {
                    refund: `$${calculateProration(
                      originalSubscriptionData?.purchases[0].purchaseConfirmedDate,
                      originalSubscriptionData?.purchases[0].productDetails.price!,
                      originalSubscriptionData?.purchases[0].productDetails.subscriptionDuration!,
                    )}`,
                    cancelledSubscription:
                      originalSubscriptionData?.purchases[0].productDetails.title,
                  })}
                </Text>
              </GridItem>
              <GridItem colStart={2} colSpan={1} rowStart={11} rowSpan={1}>
                <Text fontSize="sm" textAlign="left" marginY={5}>
                  {t('ecommerce.subscriptionManagement.changeSubscriptionHowItWorks3', {
                    newCharge: selectedSubscription?.price,
                    duration: durationToDisplay[selectedSubscription?.subscriptionDuration!],
                    newSubscription: selectedSubscription?.title,
                  })}
                </Text>
              </GridItem>
              <GridItem colStart={1} colSpan={2} rowStart={12} rowSpan={1}>
                <Divider borderTop=".25px solid" opacity="20%" />
              </GridItem>
            </Grid>
            <Button colorScheme="blue" type="submit" onClick={() => createSubscription.mutate()}>
              {t('ecommerce.checkout.button.completeCheckout')}
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
}
