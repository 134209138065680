import { graphQLClient } from '@/client/services/api/clients/graphqlClient';
import {
  getProduct as getProductQuery,
  listProducts as listProductsQuery,
  listPurchases as listPurchasesQuery,
  listPurchasesSingleUser as listPurchasesSingleUserQuery,
  createProduct as createProductMutation,
  updateProduct as updateProductMutation,
  productReportData as productReportDataQuery,
  archiveProduct as archiveProductMutation,
  getPurchaserPayments as getPurchaserPaymentsQuery,
} from '@/client/services/api/graphql/queries/products';
import {
  Product,
  ProductCreateInput,
  ProductUpdateInput,
  QueryProductsArgs,
  QueryPurchasesArgs,
  Purchase,
  QueryProductReportArgs,
  ProductReportDatum,
  PaymentsGto,
} from '@/client/services/api/graphql/gql/graphql';
import { ProductListResults, PurchaseListResults } from '@/client/services/api/graphql/gql-utils';

export async function getProduct(id: string | undefined): Promise<Product | null> {
  if (!id) return null;
  const data: any = await graphQLClient.request(getProductQuery, { id });
  return data.product;
}

export async function listProducts(options: QueryProductsArgs): Promise<ProductListResults> {
  const data: any = await graphQLClient.request(listProductsQuery, options);
  if (!data.products) {
    return {
      products: [],
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
  }
  const products: Product[] = data.products.edges.map((productNode: any) => productNode.node);
  const { pageInfo } = data.products;
  return { products, pageInfo };
}

export async function createProduct(body: ProductCreateInput): Promise<any> {
  const data = await graphQLClient.request(createProductMutation, body);
  return data;
}

export async function updateProduct(id: string, body: ProductUpdateInput): Promise<any> {
  const bodyWithId = { id, ...body };
  const data = await graphQLClient.request(updateProductMutation, bodyWithId);
  return data;
}

export async function listPurchases(options: QueryPurchasesArgs): Promise<PurchaseListResults> {
  const queryToUse = options.user ? listPurchasesSingleUserQuery : listPurchasesQuery;
  const data: any = await graphQLClient.request(queryToUse, options);
  if (!data.purchases) {
    return {
      purchases: [],
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
  }
  const purchases: Purchase[] = data.purchases.edges.map((productNode: any) => productNode.node);
  const { pageInfo } = data.purchases;
  return { purchases, pageInfo };
}
export async function listPayments(): Promise<PaymentsGto[]> {
  const data: any = await graphQLClient.request(getPurchaserPaymentsQuery, {});
  if (!data.payments) {
    return [];
  }
  return data.payments;
}

export async function getRevenueReportData(
  options: QueryProductReportArgs,
): Promise<ProductReportDatum[]> {
  const data: any = await graphQLClient.request(productReportDataQuery, options);
  const revenueData: ProductReportDatum[] = data.productReport.edges.map(
    (productNode: any) => productNode.node,
  );
  return revenueData;
}

export async function archiveProduct(id: string, archive: boolean): Promise<{archiveProduct: {id: string}}> {
  const data: {archiveProduct: {id: string}} = await graphQLClient.request(archiveProductMutation, {id, archiveInput: archive});
  return data;
}

const ProductsService = {
  getProduct,
  listProducts,
  createProduct,
  updateProduct,
  listPurchases,
  getRevenueReportData,
  archiveProduct,
  listPayments,
};

export default ProductsService;
