import { Box, Input, Text, Textarea, VStack } from '@chakra-ui/react';

import { useQuery } from '@tanstack/react-query';
import { useFormContext, Controller } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import { Product, OrderingDirection } from '@/client/services/api/graphql/gql/graphql';
import ProductsService from '@/client/services/api/graphql/ProductsService';
import { InputWithTagList } from '@/client/components/forms/registration-forms/InputWithTagList';
import { ShoppingBag } from '@/client/components/icons/ContinuIcons';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import { useDebounceValue } from 'usehooks-ts';

type DetailsProps = {
  onNextButtonClick: () => void;
};

export default function ProductLandingPageDetails({ onNextButtonClick }: DetailsProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const { watch, getValues, setValue } = useFormContext();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);


  // list products
  const { data: selectedProducts, refetch: refetchSelectedProducts } = useQuery({
    queryKey: ['selected-products'],
    queryFn: () =>
      ProductsService.listProducts({
        id: { in: watch('products') },
        page: 0,
        pageSize: 20,
        includeTotalCount: false,
        ordering: [{ field: 'purchaseCount', direction: OrderingDirection.Desc }],
      }),
    enabled: !!watch('products')?.length,
  });

  // search products
  const { data: searchedProducts } = useQuery({
    queryKey: ['searched-products', searchTerm],
    queryFn: () =>
      ProductsService.listProducts({
        title: { contains: searchTerm },
        page: 0,
        pageSize: 20,
        includeTotalCount: false,
        ordering: [{ field: 'purchaseCount', direction: OrderingDirection.Desc }],
      }),
    enabled: !!searchTerm?.length,
  });

  const productSelected = (selectedItem: Product) => {
    const currentProducts = getValues('products') || [];
    currentProducts.push(selectedItem.id);
    setValue('products', currentProducts);

    refetchSelectedProducts();
  };

  const productDelete = (product: Product) => {
    setValue(
      'products',
      selectedProducts?.products
        .filter((filterTag: Product) => filterTag.id !== product.id)
        .map((filteredTag: Product) => filteredTag.id),
    );
    refetchSelectedProducts();
  };

  const checkIfSelected = (item: Product, selectedItemIds: string[]) => {
    const itemSelected = selectedItemIds?.find(
      (selectedItemId: string) => selectedItemId === item.id,
    );
    return !!itemSelected;
  };

  return (
    <Box>
      <Text fontWeight="500" mb={4}>
        {t('ecommerce.landingPageCreate.header.productLandingPage')}
      </Text>
      <VStack alignItems="start">
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.name')} />
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, value } }) => (
            <Input
              type="text"
              onChange={onChange}
              value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.name')}`}
              variant="landingPage"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.headline')} />
        <Controller
          control={control}
          name="headline"
          render={({ field: { onChange, value } }) => (
            <Input
              type="text"
              onChange={onChange}
              value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.headline')}`}
              variant="landingPage"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.description')} />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Textarea
              onChange={onChange}
              value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.description')}`}
              variant="landingPage"
              height="140px"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.productsListed')} />
        <InputWithTagList
          fieldName="products"
          selectedItems={selectedProducts?.products || []}
          selectItem={productSelected}
          deleteItem={productDelete}
          checkIfSelected={checkIfSelected}
          searchTerm={searchTerm}
          inputPlaceholder={`${t('ecommerce.landingPageCreate.placeholder.productsListed')}`}
          setSearchTerm={setSearchTerm}
          searchedItems={searchedProducts?.products || []}
          ItemsIcon={ShoppingBag}
        />
        <SolidButton marginTop={6} alignSelf="flex-end" onClick={() => onNextButtonClick()}>
          {t('button.action.saveAndContinue')}
        </SolidButton>
      </VStack>
    </Box>
  );
}
