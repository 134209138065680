import type {
  VideoDraftData,
  VideoSubmissionData,
} from '@/client/types/admin/content-authoring/video/VideoSubmissionData';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import AuthoringGraphqlService from '@/client/services/api/admin/content-authoring/AuthoringGraphqlService';
import AuthoringVideoService from '@/client/services/api/admin/content-authoring/AuthoringVideoService';
import GenerativeService from '@/client/services/api/graphql/GenerativeService';
import { TextGenerationStatus } from '@/client/services/api/graphql/gql/graphql';
import type { VideoFormData } from '@/client/routes/admin/create/content/EditVideo';
import axios from 'axios';
import { useAuthStore } from '@/client/services/state/authStore';
import { useConfigStore } from '@/client/services/state/configStore';
import { useFormContext } from 'react-hook-form';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface YoutubeItem {
  id: string;
  snippet: { title: string; description: string };
  contentDetails: { duration: string };
}

interface YoutubeData {
  items: YoutubeItem[];
}

interface VimeoItem {
  type: string;
  version: string;
  provider_name: string;
  provider_url: string;
  title: string;
  author_name: string;
  author_url: string;
  is_plus: string;
  account_type: string;
  html: string;
  width: number;
  height: number;
  duration: number;
  description: string;
  thumbnail_url: string;
  thumbnail_width: number;
  thumbnail_height: number;
  thumbnail_url_with_play_button: string;
  upload_date: string;
  video_id: number;
  uri: string;
}

export const useAuthorVideo = (contentId: string | undefined) => {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { setToast } = useToastStore();
  const { config } = useConfigStore();
  const { setValue, watch } = useFormContext<VideoFormData>();
  const [embedLink, setEmbedLink] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const currentLink = watch('link');
  const currentDuration = watch('duration');
  const videoId = watch('id');
  const isDraft = watch('draft');
  const isTranscoding = watch('transcoding');
  const videoUploadType = watch('videoUploadType');
  const isTextGenerating = watch('textGenerationStatus');
  const source = watch('source');

  const getVideoForEditor = useQuery({
    enabled: !!contentId,
    queryKey: ['video-for-editor', contentId, user._id],
    queryFn: () => AuthoringGraphqlService.getVideoForEditor(contentId, user._id),
  });

  useEffect(() => {
    if (videoUploadType === 'embed' && currentLink) {
      setEmbedLink(currentLink);
    }
  }, []);

  const makeDuration = (link: string) => {
    const video = document.createElement('video');
    video.setAttribute('src', link);

    const wait = () => {
      setTimeout(() => {
        if (video.readyState < 1) {
          wait();
          return;
        }

        setValue('duration', currentDuration);
      }, 100);
    };

    if (video.readyState < 1) {
      wait();
      return;
    }

    setValue('duration', currentDuration);
  };

  const submitVideoPayload: Omit<
    VideoSubmissionData,
    'approval_required' | 'approved' | 'draft' | 'createdAt' | 'transcoding'
  > = {
    allow_comments: watch('allowComments'),
    author: watch('author'),
    company: company._id,
    description: watch('description'),
    duration: watch('duration'),
    explore_hide: !watch('exploreHide'),
    images: {
      explore: watch('image'),
    },
    link: watch('link'),
    linked_category: watch('linkedCategory')?.map((category) => category.id),
    partner_permissions: watch('partnerPermissions'),
    privacy_collaborators: watch('privacyCollaborators')?.map((collaborator) => collaborator.id),
    privacy_departments: watch('privacyDepartments')?.map((department) => department.id),
    privacy_grades: watch('privacyGrades')?.map((grade) => grade.id),
    privacy_groups: watch('privacyGroups')?.map((group) => group.id),
    privacy_locations: watch('privacyLocations')?.map((location) => location.id),
    privacy_org_levels: watch('privacyOrgLevels')?.map((orgLevel) => orgLevel.id),
    privacy_teams: watch('privacyTeams')?.map((team) => team.id),
    private: watch('private'),
    rating_configuration: {
      allow_edits: watch('ratingConfiguration.allowEdits'),
      allow_feedback: watch('ratingConfiguration.allowFeedback'),
      allow_rating: watch('ratingConfiguration.allowRating'),
      messaging: {
        individuals: watch('ratingConfiguration.messaging.individuals')?.map(
          (individual) => individual.id,
        ),
        slack_channels: watch('ratingConfiguration.messaging.slackChannels')?.map(
          (channel) => channel.id,
        ),
      },
      notify_feedback_only: watch('ratingConfiguration.notifyFeedbackOnly'),
      show_users_rating: watch('ratingConfiguration.showUsersRating'),
      total_value: watch('ratingConfiguration.totalValue'),
    },
    source: watch('source'),
    source_id: watch('sourceId'),
    surveys: watch('surveys')?.map((survey) => ({ survey_id: survey.id })),
    // TODO: Fix this circular type dependency
    // @ts-ignore
    tags: watch('tags'),
    title: watch('title'),
    type: 'video',
    user: watch('creator'),
    vtt_files: watch('vttFiles'),
    _id: watch('id'),
  };

  type SubmitVideoMutationVariables = {
    approvalVariables: Pick<VideoSubmissionData, 'approval_required' | 'approved' | 'draft'>;
    transcoding?: boolean;
    link?: string;
  };

  const submitVideoMutation = useMutation({
    mutationFn: async ({ approvalVariables, transcoding, link }: SubmitVideoMutationVariables) => {
      let payload: Omit<VideoSubmissionData, 'createdAt'> = {
        ...submitVideoPayload,
        ...approvalVariables,
      };

      if (typeof isTranscoding !== 'undefined') {
        payload = { ...payload, transcoding };
      }

      if (typeof link !== 'undefined') {
        payload = { ...payload, link };
      }

      const response = await AuthoringVideoService.submitVideo(payload);

      return response;
    },
    onError: () => {
      setToast({ show: true, status: 'error', title: 'Error Creating Video' });
    },
  });

  interface TranscodeMutationVariables {
    videoId: string;
    s3ObjectKey: string;
  }

  const transcodeMutation = useMutation({
    mutationFn: (variables: TranscodeMutationVariables) =>
      AuthoringVideoService.startVideoTranscodeJob(variables.videoId, variables.s3ObjectKey),
    onSuccess: () => {
      setValue('transcoding', true);

      setToast({ show: true, status: 'success', title: t('authoring.video.uploadSuccessful') });
    },
    onError: () => {
      setToast({ show: true, status: 'error', title: t('authoring.video.transcode.error') });
    },
  });

  const draftPayload: VideoDraftData = {
    approved: false,
    draft: true,
    link: watch('link'),
    source: watch('source'),
    title: watch('title') || 'Draft, needs title',
    transcoding: true,
    type: watch('contentType'),
    user: user._id,
    source_id: watch('sourceId'),
  };

  interface SaveInitialDraftMutationVariables {
    payload: VideoDraftData;
    s3ObjectKey?: string;
  }

  const saveInitialDraftMutation = useMutation({
    mutationFn: ({ payload }: SaveInitialDraftMutationVariables) =>
      AuthoringVideoService.saveInitialDraft(payload),
    onSuccess: async (data, variables) => {
      setValue('id', data._id);
      setValue('link', data.link);
      setValue('source', data.source);
      setValue('transcoding', data.transcoding);
      setValue('creator', data.user);

      if (typeof variables.s3ObjectKey === 'undefined') return;

      await transcodeMutation.mutateAsync({
        videoId: data._id,
        s3ObjectKey: variables.s3ObjectKey,
      });
    },
    onError: () => {
      setValue('videoDetails', null);

      setToast({ show: true, status: 'error', title: t('authoring.video.errorSaving') });
    },
  });

  const uploadVideoMutation = useMutation({
    mutationFn: (file: File) => AuthoringVideoService.uploadVideo(file, setUploadProgress),
    onSuccess: async (data) => {
      const { cloudFrontLink, s3ObjectKey } = data;

      setValue('link', cloudFrontLink);

      videoId
        ? await submitVideoMutation
            .mutateAsync({
              approvalVariables: {
                approval_required: user.is_collaborator,
                approved: !user.is_collaborator,
                draft: isDraft,
              },
              transcoding: true,
              link: cloudFrontLink,
            })
            .then(() => transcodeMutation.mutateAsync({ videoId, s3ObjectKey }))
        : await saveInitialDraftMutation.mutateAsync({
            payload: { ...draftPayload, link: cloudFrontLink },
            s3ObjectKey,
          });
    },
  });

  const parseYoutubeDuration = (item: string) => {
    let temp = '';
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (item.slice(0, 2) !== 'PT') {
      return 0;
    }

    // eslint-disable-next-line no-param-reassign
    item = item.slice(2);

    for (let i = 0; i < item.length; i += 1) {
      switch (item[i]) {
        case 'H':
          // eslint-disable-next-line radix
          hours = parseInt(temp) * 3600;
          temp = '';
          break;
        case 'M':
          // eslint-disable-next-line radix
          minutes = parseInt(temp) * 60;
          temp = '';
          break;
        case 'S':
          // eslint-disable-next-line radix
          seconds = parseInt(temp);
          temp = '';
          break;
        default:
          temp += item[i];
          break;
      }
    }

    return hours + minutes + seconds;
  };

  const getYoutubeData = async (youtubeVideoId: string): Promise<YoutubeItem> => {
    const response = await axios.get<YoutubeData>(`https://www.googleapis.com/youtube/v3/videos`, {
      params: {
        id: youtubeVideoId,
        key: config.youtube.apiKey,
        part: 'snippet,contentDetails',
        fields: 'items(id,snippet(title,description),contentDetails(duration))',
      },
    });

    return response.data.items[0];
  };

  interface YouTubeMutationVariables {
    videoId: string;
    embedLink: string;
  }

  const youtubeDataMutation = useMutation({
    mutationFn: (variables: YouTubeMutationVariables) => getYoutubeData(variables.videoId),
    onSuccess: (data, variables) => {
      let duration = 0;

      if (data.contentDetails && data.contentDetails.duration) {
        duration = parseYoutubeDuration(data.contentDetails.duration);
      }

      setValue('link', variables.embedLink);
      setValue('title', data.snippet.title);
      setValue('description', data.snippet.description);
      setValue('duration', duration);
      setValue('video_type', 'video/youtube');
      setValue('source', 'youtube');
      setValue('sourceId', data.id);

      saveInitialDraftMutation.mutateAsync({
        payload: {
          ...draftPayload,
          link: variables.embedLink,
          source: 'youtube',
          source_id: data.id,
          transcoding: false,
        },
      });
    },
    onError: () => {
      setToast({ show: true, status: 'error', title: t('edit.videos.check_private') });
    },
  });

  const getVimeoData = async (videoLink: string): Promise<VimeoItem> => {
    const response = await axios.get(`https://vimeo.com/api/oembed.json?url=${videoLink}`);

    return response.data;
  };

  interface VimeoMutationVariables {
    videoLink: string;
  }

  const vimeoDataMutation = useMutation({
    mutationFn: (variables: VimeoMutationVariables) => getVimeoData(variables.videoLink),
    onSuccess: (data, variables) => {
      setValue('link', variables.videoLink);
      setValue('title', data.title);
      setValue('description', data.description);
      setValue('duration', data.duration);
      // setValue('images.explore', data.thumbnail_url); // TODO images are not being returned properly from getOne
      setValue('video_type', 'video/vimeo');
      setValue('source', 'vimeo');
      setValue('sourceId', data.video_id.toString());

      saveInitialDraftMutation.mutateAsync({
        payload: {
          ...draftPayload,
          link: variables.videoLink,
          source: 'vimeo',
          source_id: data.video_id.toString(),
          transcoding: false,
        },
      });
    },
    onError: () => {
      setToast({ show: true, status: 'error', title: t('edit.videos.check_private') });
    },
  });

  const handleAddLink = () => {
    const regex =
      /(youtube)\.com\/watch\?v=([A-Za-z0-9_-]+)|(vimeo)\.com\/([0-9]+)|(youtu)\.be\/([A-Za-z0-9_-]+)/i;
    const regexVimeoEdit =
      /(youtube)\.com\/watch\?v=([A-Za-z0-9_-]+)|(vimeo)\.com\/video\/([0-9]+)/i;

    let matches = regex.exec(embedLink);

    if (!matches) {
      matches = regexVimeoEdit.exec(embedLink);
    }

    if (matches) {
      const type = matches[1] || matches[5] ? 'youtube' : 'vimeo';

      let idIdx;

      if (matches[1]) {
        idIdx = 2;
      }

      if (matches[3]) {
        idIdx = 4;
      }

      if (matches[5]) {
        idIdx = 6;
      }

      // @ts-ignore
      const matchId = matches[idIdx];

      type === 'youtube'
        ? youtubeDataMutation.mutateAsync({ videoId: matchId, embedLink })
        : vimeoDataMutation.mutateAsync({ videoLink: embedLink });
    } else {
      setValue('link', embedLink);
      setValue('source', 'remote');
      setValue('video_type', 'video/mp4');

      makeDuration(embedLink);
    }
  };

  const { data: transcoding } = useQuery({
    enabled: !!videoId && isTranscoding,
    queryKey: ['transcoding', videoId],
    queryFn: () => AuthoringVideoService.transcodingStatus(videoId),
    refetchInterval: 15000,
  });

  useEffect(() => {
    if (transcoding?.transcoding) {
      setValue('lastTranscodeCheck', transcoding.timeStamp);
      return;
    }

    setValue('transcoding', false);
  }, [transcoding]);

  const { data: textGenerationStatus } = useQuery({
    enabled:
      !!videoId &&
      source === 'custom' &&
      (isTextGenerating === TextGenerationStatus.InProgress || isTextGenerating === undefined),
    queryKey: ['text_generation_status', videoId, user._id],
    queryFn: () => GenerativeService.getTextGenerationStatus(videoId, 'video', user._id),
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (textGenerationStatus === TextGenerationStatus.InProgress) {
      return;
    }
    if (textGenerationStatus === TextGenerationStatus.Completed) {
      setValue('textGenerationStatus', TextGenerationStatus.Completed);
    }
    if (textGenerationStatus === TextGenerationStatus.Failed) {
      setValue('textGenerationStatus', TextGenerationStatus.Failed);
    }
  }, [textGenerationStatus]);

  return {
    getVideoForEditor,
    vimeoDataMutation,
    embedLink,
    setEmbedLink,
    currentLink,
    handleAddLink,
    uploadProgress,
    uploadVideoMutation,
    transcoding,
    submitVideoMutation,
    saveInitialDraftMutation,
  };
};
