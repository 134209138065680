// ! Styles for backwards compatability when viewing content created in Froala

export const froalaStyles = {
  '.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before': {
    left: '20px',
    color: '#2D2D2D',
  },
  '.tinymce-editor.fr-view p': {
    'padding-left': '20px',
  },
  '.fr-view > p': {
    marginBottom: '26px',
  },
  '.fr-view': {
    color: 'gray.600',
    lineHeight: '27px',
    fontSize: '18px',
    WebkitFontSmoothing: 'auto',
  },
  '.fr-view--comment': {
    fontSize: '14px',
  },
  '.fr-view ul, .fr-view ol': {
    paddingInlineStart: '2rem',
  },
  '.fr-view ol li, .fr-view ul li': {
    fontSize: '18px',
    lineHeight: '1.75rem',
    color: 'gray.600',
  },
  '.fr-view ol li a, .fr-view ul li a': {
    color: 'brand.link',
  },
  '.fr-view a:not(.tinymce-editor.fr-view a)': {
    color: 'brand.link',
  },
  '.fr-view p': {
    margin: '0 0 26px',
    lineHeight: '1m',
    fontSize: '18px',
    color: 'gray.600',
  },
  '.assignments--admin .fr-view p': {
    margin: '0 0 1rem',
  },
  '.fr-view h1, .fr-view h2,.fr-view h3, .fr-view h4': {
    lineHeight: '29px',
    margin: 'calc(2rem - 0.14285em) 0 2rem',
    fontWeight: 300,
    padding: '0',
  },
  '.fr-view h1': {
    fontSize: '22px',
    fontWeight: 500,
    marginBottom: '24px',
  },
  '.fr-view h2': {
    fontSize: '24px',
  },
  '.fr-view h3': {
    fontSize: '20px',
  },
  '.fr-view blockquote': {
    padding: ['0', '0 0 0 20px'],
    margin: '0 0 20px',
    fontSize: '18px',
    borderLeft: '5px solid gray',
    borderColor: 'gray.400',
    color: 'gray.400',
    fontWeight: '300',
    boxShadow: 'inset 5px 0 0 gray.600, -20px 0 0 0 white',
    transition: '0.2s ease all',
    WebkitFontSmoothing: 'auto',
    _hover: {
      borderColor: 'brand.primary',
    },
  },
  '.fr-view blockquote:before': {
    transition: '0.3s ease all',
  },
  '.fr-view blockquote:hover': {
    boxShadow: 'inset 5px 0 0 brand.primary , -20px 0 0 0 white',
  },
  '.fr-view blockquote:hover:before': {
    background: 'brand.primary',
  },
  '.fr-view blockquote p': {
    display: 'inline-block',
    padding: '15px 30px',
    margin: '0',
    color: 'gray.600',
  },
  '.fr-view blockquote blockquote': {
    border: '0',
    color: 'gray.600',
  },
  '.fr-view blockquote cite': {
    paddingLeft: '30px',
  },
  '.fr-view table': {
    width: '100% !important',
    maxWidth: '100% !important',
  },
  '.fr-view .fr-dib,\n.fr-view img.fr-dib': {
    height: 'auto !important',
  },
  '.fr-view .fr-img-wrap': {
    display: 'inline-block',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  '.fr-view .fr-img-wrap .fr-inner': {
    marginTop: '1rem',
    display: 'block',
    fontSize: '12px',
    lineHeight: 1.45,
  },
  '.fr-view ol ol': {
    listStyleType: 'lower-alpha',
  },
  '.fr-view ol ol ol': {
    listStyleType: 'lower-roman',
  },
  '.fr-view ol ol ol ol': {
    listStyleType: 'decimal',
  },
  '.fr-view ol ol ol ol ol': {
    listStyleType: 'lower-alpha',
  },
  '.fr-view ol ol ol ol ol ol': {
    listStyleType: 'lower-roman',
  },
  '.fr-view ol ol ol ol ol ol ol': {
    listStyleType: 'decimal',
  },
  '.fr-view ol, .fr-view ul': {
    listStylePosition: 'outside',
    marginBottom: 6,
  },
  // '.fr-view ul li ul': {
  //   margin: 0,
  // },
  '.fr-view table td,\n.fr-view table th': {
    padding: '5px',
  },
  '.fr-view table td p,\n.fr-view table th p': {
    fontSize: '14px',
  },
  '.display__header__inner .fr-view p': {
    color: 'white',
  },
  '.fr-view pre': {
    background: 'gray.50',
    padding: '2rem',
    borderRadius: '4px',
    fontFamily: 'monospace !important',
    border: '1px solid gray',
    borderColor: 'gray.400',
  },
  '.fr-view .fr-inlineCode, \n .fr-view .fr-class-code': {
    background: 'gray.50',
    color: 'gray.600',
    padding: '1px 0',
    borderRadius: '2px',
    border: '1px solid gray',
    borderColor: 'gray.400',
    fontFamily: 'monospace !important',
  },
  '.fr-view img': {
    display: 'inline',
  },
  '.fr-view img.fr-dii.fr-fil': {
    margin: '30px 30px 30px 0 !important',
  },
  '.fr-view img.fr-dii.fr-fir': {
    margin: '30px 0 30px 30px !important',
  },
  '.fr-view img.fr-shadow': {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.45)',
    padding: '0',
  },
  '.fr-view img.fr-bordered': {
    border: '2px solid brand.primary',
  },
  '.fr-view img.fr-centered': {
    margin: '0 auto',
    display: 'block',
  },
  '.fr-view img.fr-bullet': {
    display: 'inline !important',
    verticalAlign: 'middle !important',
  },
  '.fr-view .fr-img-caption .fr-inner': {
    marginTop: '0.5rem',
  },
  '.fr-view .fr-img-caption.fr-shadow img': {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.45)',
    padding: '0',
  },
  '.fr-view .fr-element img': {
    padding: '0',
  },
  '.fr-view .fr-view--grader': {
    fontSize: '14px',
  },
  '.fr-view .fr-view--grader p, .fr-view .fr-view--grader ul, .fr-view .fr-view--grader li, .fr-view .fr-view--grader a, .fr-view .fr-view--grader b, .fr-view .fr-view--grader i,.fr-view .fr-view--grader u':
    {
      fontSize: '14px',
    },
  '.fr-class-code': {
    background: 'gray.300',
    color: 'gray.600',
    padding: '1px 5px !important',
    borderRadius: '2px',
    border: '1px solid gray.600',
    fontFamily: 'monospace !important',
  },
  '.fr-class-code-dark': {
    background: 'gray.600',
    color: 'gray.300',
    padding: '1px 5px !important',
    borderRadius: '2px',
    fontFamily: 'monospace !important',
  },
  '.fr-view .fr-file::after': {
    content: "'\\1F4CE'",
    fontFamily: 'Icons',
  },
  '.fr-view .fr-text-title': {
    fontSize: '24px !important',
    fontWeight: 700,
  },
  '.fr-view .fr-text-branded': {
    color: 'brand.primary !important',
  },
  '.fr-view .fr-alternate-rows tbody tr:nth-of-type(even)': {
    background: '#f9f9f9',
  },
  '.fr-view .fr-dashed-borders thead tr th, .fr-view .class2 tbody tr td': {
    borderStyle: 'dashed',
  },
  '.fr-view .fr-fluid-width': { width: '100%' },
  '.facilitator-details .fr-view p': {
    fontSize: '12px',
  },
  '.fr-view img.fr-dib': {
    margin: '5px auto',
    display: 'block',
    float: 'none',
    'vertical-align': 'top',
  },
  '.fr-view .fr-video.fr-dvb': {
    clear: 'both',
    display: 'block',
  },
  '.fr-view .fr-video': {
    position: 'relative',
    'text-align': 'center',
  },
};
