import {
  Box,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { ExploreCategory } from '../../types/ExploreContent';
import ExploreContentGrid from '@/client/components/lists/explore/ExploreContentGrid';
import Loading from '@/client/components/media/Loading';
import { useAuthStore } from '@/client/services/state/authStore';
import { useExploreStore } from '@/client/services/state/routeState/exploreStore';
import { useGetExploreContent } from '../../services/hooks/useGetExploreContent';
import { useInView } from 'react-intersection-observer';
import { useSearchExploreContent } from '@/client/services/hooks/useSearchExploreContent';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ContentListProps = {
  cardType: 'card' | 'list';
  category?: ExploreCategory | undefined;
  secondLevelCategories: ExploreCategory[];
  thirdLevelCategories: ExploreCategory[];
  isFiltering: boolean;
  restrictedTypes: string;
  isSearching: boolean;
  searchQuery: string;
  searchTerm: string;
  recordSearch?: (contentSelectedId: string, contentSelectedType: string) => void | undefined;
};

export default function ContentList({
  cardType,
  category = undefined,
  secondLevelCategories,
  thirdLevelCategories,
  isFiltering,
  restrictedTypes,
  isSearching,
  searchQuery,
  searchTerm,
  recordSearch = () => {},
}: ContentListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { ref, inView } = useInView();
  const [secondLevelOptions, setSecondLevelOptions] = useState<ExploreCategory[]>([]);
  const [thirdLevelOptions, setThirdLevelOptions] = useState<ExploreCategory[]>([]);
  const isPurchaser = company.feature_flags.enable_ecommerce && user.is_purchaser;

  const {
    selectedTopic,
    setSelectedTopic,
    selectedSubTopic,
    setSelectedSubTopic,
    selectedCategory,
  } = useExploreStore();

  const { data, isFetchingNextPage, fetchNextPage, status } = useGetExploreContent(
    isSearching,
    isFiltering,
    selectedCategory,
    restrictedTypes,
    isPurchaser,
  );

  const {
    data: searchData,
    status: statusSearch,
    fetchNextPage: fetchNextSearchPage,
    isFetchingNextPage: isFetchingNextSearchPage,
  } = useSearchExploreContent(isSearching, searchQuery, searchTerm);

  useEffect(() => {
    if (inView) {
      isSearching ? fetchNextSearchPage() : fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (category) {
      setSecondLevelOptions(
        secondLevelCategories.filter((cat: any) => cat.parent === category._id),
      );
    }
  }, [secondLevelCategories]);

  useEffect(() => {
    setThirdLevelOptions(
      thirdLevelCategories.filter((cat: any) => cat.parent === selectedTopic?._id),
    );
  }, [selectedTopic]);

  if ((!isSearching && status === 'loading') || (isSearching && statusSearch === 'loading')) {
    return <Loading />;
  }

  if (status === 'error') return <Box>Error getting content</Box>;

  return (
    <>
      {secondLevelOptions && secondLevelOptions.length > 0 && (
        <Menu variant="explore">
          <MenuButton
            as={Button}
            variant="menu"
            rightIcon={<ChevronDownIcon boxSize="24px" />}
            marginBottom={4}
          >
            {selectedTopic ? selectedTopic.name : t('explore.general.selectTopic')}
          </MenuButton>

          <MenuList>
            {secondLevelOptions.map((option) => {
              if (user && user.role && user.role === 'external' && !option.show_external) {
                return;
              }

              return (
                <MenuItem
                  key={option._id}
                  onClick={() => {
                    setSelectedTopic(option);
                    searchParams.delete('subTopic');
                    setSearchParams(searchParams);
                    setSelectedSubTopic(undefined);
                  }}
                >
                  {option.name}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}

      {selectedTopic && thirdLevelOptions && thirdLevelOptions.length > 0 && (
        <Menu variant="explore">
          <MenuButton
            as={Button}
            variant="menu"
            rightIcon={<ChevronDownIcon />}
            marginLeft={4}
            marginBottom={4}
          >
            {selectedSubTopic ? selectedSubTopic.name : 'Select a Sub-Topic'}
          </MenuButton>

          <MenuList maxHeight="200px" overflow="auto">
            {thirdLevelOptions.map((option) => {
              if (user && user.role && user.role === 'external' && !option.show_external) return;

              return (
                <MenuItem key={option._id} onClick={() => setSelectedSubTopic(option)}>
                  {option.name}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}

      {isSearching && searchData?.pages && searchData.pages.length > 0 && (
        <>
          <ExploreContentGrid data={searchData} cardType={cardType} recordSearch={recordSearch} />

          <Center ref={ref} margin={10}>
            {isFetchingNextSearchPage && <Spinner />}
          </Center>
        </>
      )}

      {isSearching && searchData?.pages[0].items.length === 0 && (
        <Center>{t('explore.grid.no_results')}</Center>
      )}

      {!isSearching && data && data.pages && data.pages.length > 0 && (
        <>
          <ExploreContentGrid data={data} cardType={cardType} />

          <Center ref={ref} margin={10}>
            <div>{isFetchingNextPage && <Spinner />}</div>
          </Center>
        </>
      )}

      {!isSearching && data && data.pages[0].items.length === 0 && (
        <Center>No content found</Center>
      )}
    </>
  );
}
