import ContentSegmentationSearchService from '@/client/services/api/admin/segmentation/ContentSegmentationSearchService';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export const useAuthoringContentSearch = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { isLoading, isFetching, isError, data } = useQuery({
    enabled: !!searchTerm,
    queryKey: [
      'user-group-search',
      searchTerm,
      'articles,videos,files,scorm,imported_content,workshops,tracks,journeys,',
      true,
      true,
    ],
    queryFn: () =>
      ContentSegmentationSearchService.search(
        searchTerm,
        'articles,videos,files,scorm,imported_content,workshops,tracks,journeys,',
        true,
        true,
      ),
  });

  return {
    searchTerm,
    setSearchTerm,
    isLoading,
    isFetching,
    isError,
    data,
  };
};
